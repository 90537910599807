.bottom-nav-wrapper img {
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.75rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  padding: 0.125em;
};

label {
  color: #bb0000;
  font-size: medium;
  font-weight: bold;
};

.InlineDiv {
  display: flex;
  align-items: center;

};
.scarlet{
  color: #bb0000;
};

.scarletButton {
  background-color: #BB0000;
  color: #fff;
}
.ui.button {
  background-color: #666666;
  color: #fff;
}

.buckeyeNut{
  width:20px;
  height:20px;
}

.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
