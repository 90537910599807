@import "./imports";

html {
  height: 100%;
  --mobile-nav-height: 4rem;
  --osu-scarlet: #bb0000;
  --osu-scarlet-20: #960000;
  --osu-gray: #666666;
  --osu-gray-20: #e0e0e0;
  --osu-gray-70: #1e1e1e;
  --root-text-color: black;
  --root-background-color: white;
  --img-border: 0.2px solid rgba(128, 128, 128, 0.3);
  --palette-foreground: black;
  --palette-midground: black;
  --palette-background: black;
  --palette-primary-light: #0f4fc9;
  --palette-primary-dark: #0f4fc9;
  --palette-primary-main: black;
  --palette-primary-contrastTest: black;
  --palette-secondary-light: #0f4fc9;
  --palette-secondary-dark: #0f4fc9;
  --palette-secondary-main: black;
  --palette-secondary-contrastTest: black;
  --palette-error-light: #0f4fc9;
  --palette-error-dark: #0f4fc9;
  --palette-error-main: black;
  --palette-error-contrastTest: black;
  color: var(--root-text-color);
  background-color: var(--root-background-color);
}
select :-moz-any(option) {
  color: var(--root-text-color);
  background-color: var(--options-background-color) !important;
}
#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
footer {
  flex-shrink: 0;
}
.main-wrapper {
  flex: 1 0 auto;
}
.h-accessible-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.d-flex {
  display: flex !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.align-items-center {
  align-items: center !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.spaceBetween {
  justify-content: space-between !important;
}
.justify-items-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.m-0-auto {
  margin: 0 auto !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.pointer {
  cursor: pointer !important;
}
.ellipsis {
  text-overflow: ellipsis;
}