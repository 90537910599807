.drawer {
  width: 240px;
  flex-shrink: 0;
}
.MuiDrawer-paper {
  width: 240px;
}
.drawerContainer {
  overflow: auto;
}
